import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthGuard } from './shared/auth/auth-guard.service';
import { AuthInterceptorService } from './shared/auth/api-Interceptor.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AppQrDownloadComponent } from './app-qr-download/app-qr-download.component';
import { AuthRedirectGuard } from './shared/auth/auth-redirect-guard.guard';
import { ProcessingComponent } from './processing/processing.component';
import { NewAuthComponent } from './new-auth/new-auth.component';


@NgModule({
  declarations: [
    AppComponent,
    AppQrDownloadComponent,
    ProcessingComponent,
    NewAuthComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    StoreModule.forRoot({}, {}),
    EffectsModule.forRoot([]),
  ],
  providers: [
    AuthGuard, AuthRedirectGuard, // Add the new guard here
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
