import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';


@Injectable({
  providedIn: 'root'
})

export class ProcessingUrlCheckGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    let masterId = next.queryParamMap.get('masterId');
    if (masterId) {
      localStorage.setItem("entitywiseSubscriptionId", masterId);;
      const cleanUrl = state.url.split('?')[0];
      this.router.navigateByUrl(cleanUrl);
      return false;
    }

    return true;
  }
}
