import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProductMenuService } from '../services/product-menu.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-app-qr-download',
  templateUrl: './app-qr-download.component.html',
  styleUrl: './app-qr-download.component.scss',
})
export class AppQrDownloadComponent {
  progress = 0;
  downloadMessage = '';
  showProgressBar = false;
  currentDate: Date = new Date();
  formattedDate: string = '';

  constructor(
    private _spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private productMenuService: ProductMenuService
  ) {}

  ngOnInit(): void {
    this.formattedDate = `${String(this.currentDate.getDate()).padStart(
      2,
      '0'
    )}_${String(this.currentDate.getMonth() + 1).padStart(2, '0')}_${String(
      this.currentDate.getFullYear()
    ).slice(-2)}`;

    this.showProgressBar = true;
    this.downloadMessage = 'Downloading started...';
    this.animateProgressBar();

    this.route.url.subscribe((urlSegments) => {
      const urlPath = urlSegments.map((segment) => segment.path).join('/');

      console.log(urlPath);
      console.log(urlPath.includes('qrdownload'));

      if (urlPath.includes('qrdownload')) {
        const code = urlPath.split('qrdownload/')[1];
        this.productMenuService.AppQRDownload(code).subscribe(
          (res) => {
            this.downloadMessage = 'Download completed!';
            this.progress = 100;
            const blob = res;
            var _url = window.URL.createObjectURL(blob);
            var link = document.createElement('a');

            link.href = _url;
            link.download = `qr_${this.formattedDate}.pdf`;
            link.click();
            window.URL.revokeObjectURL(_url);
          },
          (err) => {
            this.downloadMessage = 'Download failed.';
            this.showProgressBar = false;
          }
        );
        console.log('Extracted Code:', code);
      }
    });
  }

  animateProgressBar() {
    const interval = setInterval(() => {
      if (this.progress < 90) {
        this.progress += 5;
      } else {
        clearInterval(interval);
      }
    }, 200);
  }
}
