import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from '../../environments/environment';
import { SubscriptionService } from '../services/subscription.service';
import { SharedService } from '../services/shared.service';

@Component({
  selector: 'app-processing',
  templateUrl: './processing.component.html',
  styleUrl: './processing.component.scss',
})
export class ProcessingComponent {
  masterId: any;
  entitywiseSubscriptionId: any;
  constructor(
    private _spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private router: Router,
    private subscriptionService: SubscriptionService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this._spinner.show();
    this.route.queryParams.subscribe((params) => {
      this.masterId = params['masterid'];
      this.entitywiseSubscriptionId = Number(
        localStorage.getItem('entitywiseSubscriptionId')
      );
      console.log(
        'this.entitywiseSubscriptionId',
        this.entitywiseSubscriptionId
      );

      this.subscriptionService
        .EntitywiseSubscriptionMaster(this.entitywiseSubscriptionId)
        .subscribe((res: any) => {
          if (res.isSuccess) {
            this.sharedService.updateSubscriptionData({
              success: true,
              data: res.content,
            });
            this.router.navigate(['/dashboard']);
          } else {
            this.sharedService.updateSubscriptionData({
              success: false,
              data: null,
            });
            this.router.navigate(['/dashboard']);
          }
        });
    });

    const progressBar = document.querySelector('.progress') as HTMLElement;
    setTimeout(() => {
      progressBar.style.width = '100%';
    }, 0);
  }
}
