import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';


@Injectable({
  providedIn: 'root'
})

export class UrlCheckGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    let tableId = next.queryParamMap.get('table');
    if (tableId) {
      localStorage.setItem("TableId", tableId);;
      const cleanUrl = state.url.split('?')[0]; // Remove query params
      this.router.navigateByUrl(cleanUrl);
      return false; // Prevent navigation to this route
    } 

    return true; // Allow navigation if no query param
  }
}